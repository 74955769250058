import { useRecoilValue } from "recoil";
import { IHistoricoAcoes } from "../../../commons/types/acoes";
import { useAxiosWrapper } from "../../../services/axiosWrapper";
import { authAtom } from "../../usuarios/AuthState";

function useAcoesActions() {
  const axiosWrapper = useAxiosWrapper();
  const authState = useRecoilValue(authAtom);
  const baseUrl = `${process.env.REACT_APP_API_URL}/v1/empresas/${authState!.empresaId}/comandas`;

  return {
    getHistoricoAcoes,
  }

  async function getHistoricoAcoes(id: number): Promise<IHistoricoAcoes> {
    const resp: IHistoricoAcoes = await axiosWrapper.get(`${baseUrl}/${id}/acoes`);
    return resp;
  }
}

export { useAcoesActions };

