import { useBaseRootActions } from "../baseRoot.actions";

function useRedefinirSenhaActions() {
  const baseActions = useBaseRootActions('redefinicaoSenha');

  return {
    create: baseActions.create,
    update: baseActions.update,
    get: baseActions.get,
  };
}

export { useRedefinirSenhaActions };
