import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

const ProgramaResumo = ({ programa }: any) => (
  <Box sx={{ marginBottom: 2 }}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Nome</TableCell>
          <TableCell>Programa</TableCell>
          <TableCell>Prazo</TableCell>
          <TableCell>Início</TableCell>
          <TableCell>Término</TableCell>
          <TableCell>Cargo</TableCell>
          <TableCell>Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>{programa.nome}</TableCell>
          <TableCell>{programa.programa}</TableCell>
          <TableCell>{programa.prazo}</TableCell>
          <TableCell>{programa.inicio}</TableCell>
          <TableCell>{programa.termino}</TableCell>
          <TableCell>{programa.cargo}</TableCell>
          <TableCell>{programa.status}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </Box>
);

export default ProgramaResumo;
