import { Box, Typography, List, ListItem, Grid, Divider } from '@mui/material';

const CronogramaEtapas = ({ cronograma }: any) => (
  <Box sx={{ padding: 2 }}>
    {["Think", "Plan", "Go To Market"].map((categoria, indexCategoria) => (
      <Box key={categoria} sx={{ marginBottom: 2 }}>
        <Typography variant="h6" gutterBottom>
          {categoria}
        </Typography>
        
        <List>
          {cronograma?.[categoria]?.length > 0 ? (
            cronograma[categoria].map((etapa: any, index: number) => (
              <ListItem key={index} sx={{ paddingY: 1 }}>
                <Grid container>
                  <Grid item xs={8}>
                    <Typography variant="body1">{etapa.nome}</Typography>
                  </Grid>
                  <Grid item xs={4} textAlign="right">
                    <Typography variant="body2" color="textSecondary">
                      {etapa.data}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            ))
          ) : (
            <Typography variant="body2" color="textSecondary">
              Nenhuma etapa disponível.
            </Typography>
          )}
        </List>

        {indexCategoria < 2 && <Divider sx={{ marginY: 2 }} />}
      </Box>
    ))}
  </Box>
);

export default CronogramaEtapas;
