import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { history } from "../../helpers/history";
import { Box, Grid } from '@mui/material';
import styled from "styled-components";
import CronogramaEtapas from "./Components/CronogramaEtapas";
import ProgramaResumo from "./Components/ProgramaResumo";
import { useContatosActions } from "../../states/contatos/contatos.actions";
import { calcularTempoProjeto } from "../../commons/utils/tempoProjeto";
import { useAcoesActions } from "../../states/historicoAcoes/acoes/acoes.actions";
import HistoricoAcoes from "./Components/HistoricoReunioes";

export interface IParams {
  id: string;
}

interface Etapa {
  nome: string;
  data: string | null;
}

interface Cronograma {
  Think: Etapa[];
  Plan: Etapa[];
  GoToMarket: Etapa[];
}

const Detalhes: React.FC = () => {
  const { id } = useParams<IParams>();
  const [comanda, setComanda] = useState<any>();

  const actionsContatos = useContatosActions();
  const actionsHistoricoAcoes = useAcoesActions();

  useEffect(() => {
    const fetchData = async () => {
      if (!id) history.goBack();

      try {
        const value = await actionsContatos.getContatoDetalhes(id);

        const findDataPrevista = (etapaId: number) => {
          const reuniao: any = value?.comanda.comandaEtapaReunioes?.find(
            (reuniao: any) => reuniao.tbEtapaId === etapaId
          );
          return reuniao ? new Date(reuniao.dtPrevista).toLocaleDateString('pt-BR') : "Sem data";
        };

        const mapHistoricoAcoes = async () => {
          const historicoAcoes: any[] = [];
          const result: any = await actionsHistoricoAcoes.getHistoricoAcoes(value?.comanda?.id);

          result.resultado.map((item: any) => {
            historicoAcoes.push({
              data: new Date(item.dtCadastro).toLocaleDateString('pt-BR'),
              nome: item.idTipoNavigation.nome,
              descricao: item.observacoes
            });
          });

          return historicoAcoes;
        };

        const mapEtapasToCronograma = (etapas: any[]): Cronograma => {
          const cronograma: Cronograma = {
            Think: [],
            Plan: [],
            GoToMarket: [],
          };

          etapas.forEach((etapa) => {
            const nomeEtapa = etapa.nome;
            const dataEtapa = findDataPrevista(etapa.id);

            if (etapa.idMetodologia === 1) {
              cronograma.Think.push({ nome: nomeEtapa, data: dataEtapa });
            } else if (etapa.idMetodologia === 2) {
              cronograma.Plan.push({ nome: nomeEtapa, data: dataEtapa });
            } else if (etapa.idMetodologia === 3) {
              cronograma.GoToMarket.push({ nome: nomeEtapa, data: dataEtapa });
            }
          });

          return cronograma;
        };

        const historico = await mapHistoricoAcoes();

        const resource = {
          programa: {
            nome: value?.contato?.nome,
            programa: value?.comanda?.plano,
            prazo: calcularTempoProjeto(new Date(value?.comanda?.dataInicio!), new Date(value?.comanda?.dataFim!)),
            inicio: new Date(value?.comanda?.dataInicio!).toLocaleDateString('pt-BR'),
            termino: new Date(value?.comanda?.dataFim!).toLocaleDateString('pt-BR'),
            status: value?.comanda?.status?.nome,
            cargo: value?.contato?.ultimoCargo,
          },
          cronograma: mapEtapasToCronograma(value?.comanda?.etapas || []),
          historico: historico
        };

        setComanda(resource);
      } catch (error) {
        console.error("Erro ao buscar os detalhes da comanda: ", error);
      }
    };

    fetchData();
  }, [id]);

  return (
    <BoxContato sx={{ width: '100%' }}>
      {comanda && (
        <>
          <ProgramaResumo programa={comanda.programa} />

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <CronogramaEtapas cronograma={comanda.cronograma} />
            </Grid>
            <Grid item xs={8}>
              <HistoricoAcoes historico={comanda.historico} />
            </Grid>
          </Grid>
        </>
      )}
    </BoxContato>
  );
}

const BoxContato = styled(Box)`
  width: 100%;
  margin: 20px;
  border-radius: 16px !important;
  background: #f5f5f5;
  border: 1px solid #aaa;
`;

export default Detalhes;
