import { Box, Typography } from '@mui/material';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@mui/lab';

const HistoricoAcoes = ({ historico }: any) => (
  <Box sx={{ padding: 2, overflowY: 'auto' }}>
    <Timeline position="alternate">
      {historico.map((reuniao: any, index: number) => (
        <TimelineItem key={index}>
          <TimelineOppositeContent>
            <Typography variant="body2" color="textSecondary">
              {reuniao.data}
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot color="primary" />
            {index < historico.length - 1 && <TimelineConnector sx={{ height: 30 }} />}
          </TimelineSeparator>
          <TimelineContent sx={{ paddingBottom: 3 }}>
            <Typography variant="h6">{reuniao.nome}</Typography>
            <Typography variant="body2">{reuniao.descricao}</Typography>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  </Box>
);

export default HistoricoAcoes;
