import { Route, Switch } from "react-router-dom";
import Pagina404 from './pages/Pagina404';
import MenuBar from './components/Menu';
import Login from './pages/Login';
import Dashboard from "./pages/Dashboard";
import Assessorados from "./pages/Assessorados";
import Detalhes from "./pages/Detalhes";
import SolicitarRedefinicaoSenha from "./pages/RedefinirSenha/SolicitarRedefinicaoSenha";
import RedefinicaoSenha from "./pages/RedefinirSenha/RedefinicaoSenha";

function Routes() {
  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/redefinir-senha" component={SolicitarRedefinicaoSenha} />
      <Route exact path="/redefinir-senha/:id" component={RedefinicaoSenha} />
      <MenuBar>
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/assessorados" component={Assessorados} />
          <Route exact path="/assessorados/:id" component={Detalhes} />
        </Switch>
      </MenuBar>
      <Route exact component={Pagina404} />
    </Switch>
  );
}

export default Routes;
