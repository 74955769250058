import { RecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useAxiosWrapper } from '../services/axiosWrapper';
import { authAtom } from './usuarios/AuthState';
import { history } from '../helpers/history';

function useBaseRootActions(
  recurso: string,
  state?: RecoilState<any>,
) {
  const baseUrl = `${process.env.REACT_APP_API_URL}/v1/${recurso}`
  const axiosWrapper = useAxiosWrapper();
  const setState = state ? useSetRecoilState(state) : undefined;
  const setAuth = useSetRecoilState(authAtom);

  return {
    list: list,
    get: get,
    update: update,
    create: create,
    delete: deleteObj,
    createFormData: createFormData,
    patch: patch,
  };

  async function list(salvarNoState: boolean = false, query: string = '') {
    let url = baseUrl;
    if (query !== '') url += query;

    try {
      const recursos = await axiosWrapper.get(url);
      if (salvarNoState && state !== undefined && setState !== undefined) setState(recursos);
      return recursos;
    } catch (error) {
      if (error === 'Usuário inválido') {
        localStorage.removeItem('usuario');
        setAuth(null);
        history.push('/login');
      }
      throw error;
    }
    
  }

  async function get(id: number) {
    try {
      const recurso = await axiosWrapper.get(`${baseUrl}/${id}`);
      return recurso;
    } catch (error) {
      if (error === 'Usuário inválido') {
        localStorage.removeItem('usuario');
        setAuth(null);
        history.push('/login');
      }
      throw error;
    }
  }

  async function update(id: number, obj: object) {
    try {
      const recurso = await axiosWrapper.put(`${baseUrl}/${id}`, obj);
      return recurso;
    } catch (error) {
      if (error === 'Usuário inválido') {
        localStorage.removeItem('usuario');
        setAuth(null);
        history.push('/login');
      }
      throw error;
    }
  }

  async function create(obj: object) {
    try {
      const recurso = await axiosWrapper.post(`${baseUrl}`, obj);
      return recurso;
    } catch (error) {
      if (error === 'Usuário inválido') {
        localStorage.removeItem('usuario');
        setAuth(null);
        history.push('/login');
      }
      throw error;
    }
  }

  async function deleteObj(id: number) {
    try {
      const recurso = await axiosWrapper.delete(`${baseUrl}/${id}`);
      return recurso;
    } catch (error) {
      if (error === 'Usuário inválido') {
        localStorage.removeItem('usuario');
        setAuth(null);
        history.push('/login');
      }
      throw error;
    }
  }

  async function createFormData(obj: object) {
    try {
      const recurso = await axiosWrapper.postFormData(`${baseUrl}`, obj);
      return recurso;
    } catch (error) {
      if (error === 'Usuário inválido') {
        localStorage.removeItem('usuario');
        setAuth(null);
        history.push('/login');
      }
      throw error;
    }
  }

  async function patch(id: number, obj: object) {
    try {
      const recurso = await axiosWrapper.patch(`${baseUrl}/${id}`, obj);
      return recurso;
    } catch (error) {
      if (error === 'Usuário inválido') {
        localStorage.removeItem('usuario');
        setAuth(null);
        history.push('/login');
      }
      throw error;
    }
  }
}

export { useBaseRootActions };
