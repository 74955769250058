import { Icon, Paper } from "@mui/material";
import { DataGrid, GridCellParams, GridColDef } from "@mui/x-data-grid";
import { debug } from "console";
import moment from "moment";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { IContato } from "../../commons/types/empresa/contatos";
import { calcularTempoProjeto } from "../../commons/utils/tempoProjeto";
import { history } from "../../helpers/history";
import { useContatosActions } from "../../states/contatos/contatos.actions";

const Assessorados: React.FC = () => {
  const actions = useContatosActions();
  const [contatos, setContatos] = useState<IContato[]>([]);
  const [loading, setLoading] = useState(true);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  useEffect(() => {
    actions
      .getContatos()
      .then((data) => setContatos(data))
      .catch((err) => toast.error("Falha ao buscar assessorados"))
      .finally(() => setLoading(false));
  }, []);

  const columns: GridColDef[] = [
    {
      field: "nome",
      headerName: "Nome",
      width: 170,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
    },
    {
      field: "faseComanda",
      headerName: "Fase",
      width: 120,
    },
    {
      field: "statusComanda",
      headerName: "Status",
      width: 120,
    },
    {
      field: "prazo",
      headerName: "Prazo",
      width: 120,
      valueGetter: (params: any, row: any) => {
        return calcularTempoProjeto(
          new Date(row.dataInicio),
          new Date(row.dataFim),
        );
      },
    },
    {
      field: "dataInicio",
      headerName: "Data Início",
      width: 100,
      valueFormatter: (params) => {
        const data = new Date(params);
        return moment(data).format("DD/MM/YYYY");
      },
    },
    {
      field: "dataFim",
      headerName: "Data Fim",
      width: 100,
      valueFormatter: (params) => {
        const data = new Date(params);
        return moment(data).format("DD/MM/YYYY");
      },
    },
    {
      field: "acoes",
      headerName: "Ações",
      width: 70,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IContato;
        return (
          <Icon
            onClick={() => navigateToDetalhes(obj.id.toString())}
            style={{ color: "gray", cursor: "pointer" }}
          >
            visibility
          </Icon>
        );
      },
    },
  ];

  const navigateToDetalhes = (id: string) => {
    history.push(`/assessorados/${id}`);
  };

  return (
    <>
      <PaperList>
        <DataGrid
          getRowId={(row) => row.id + row.nome}
          rows={contatos}
          columns={columns}
          loading={loading}
          pageSizeOptions={[10, 20, 50, 100]}
          onPaginationModelChange={setPaginationModel}
          onRowClick={(data) => navigateToDetalhes(data.row.id.toString())}
        />
      </PaperList>
    </>
  );
};

const PaperList = styled(Paper)`
  margin: 20px;
  width: 100%;
  min-height: 500px;
  border-radius: 16px !important;
`;

export default Assessorados;
